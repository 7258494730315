import React from 'react';
import { PageProps } from 'gatsby';
import { Col, Row, Container} from 'react-bootstrap';
import Breadcrumb from '../components/Layout/breadcrumb';

import Layout from '../components/Layout/layout';
import Meta from '../components/Layout/head';

export const Head = () => (
    <Meta 
        title={`Mentions légales`} 
        description={`Toutes les mentions légales et conditions générales d'utilisation`}
        lang='fr'
        pathname='/mentions-legales/'
        media=''> 
    </Meta>
)

export default function legal(location: PageProps['location']) {

    const breadcrumbArray = [['/mentions-legales/','Mentions légales']]; 

    return (
        <Layout>
            <Container className='green'>
                <Row>
                    <Col className='my-5' lg={{span:8,offset:2}} sm={12}>
                        <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                        <div className='text-center bg-light-yellow rounded-md py-5 px-2'>
                            <h1>Mentions légales, CGU et politique de confidentialité</h1>
                            <p>Vous avez une question sur Rheaparks ? Nous allons essayer d'y répondre du mieux que possible.</p> 
                        </div>
                        <div className='my-4'>
                            <h2>Conditions Générales d'Utilisation</h2>
                            <p>Dernière mise à jour : 12/04/2022</p>
                            <p>Ce site web (https://www.rheaparks.com dit le "Site") et les applications mobiles et services disponibles en connection avec ce site 
                                et l’application mobile (Rheaparks dites les "Apps") sont mise à votre disposition (l’"Utilisateur") par Clément Cardonnel et Sandra Debono, 
                                domiciliés au 32 Avenue Aristide Briand, 34170 Castelnau-le-Lez, France, sous le nom Rheaparks ("Nous"). 
                                En accédant, utilisant ou téléchargeant tout materiel depuis l’App ou le Site, vous acceptez de respecter les Conditions Générales d’Utilisation ("CGU"). 
                                Les CGU peuvent être mises à jour par Nous sans avertissement. Vous pouvez consulter la plus récente version des CGU ici. 
                                Nous et nos prestataires tiers peuvent faire des modifications dans les produits, services, les Apps ou le Site à tout moment et sans avertissement. 
                                Les Apps ne sont pas conçues et ne devraient pas être utilisées par des enfants de moins de 13 ans. 
                                Si vous n’agréez pas avec les CGU, s’il vous plaît ne faites pas usage des Apps ou du Site. 
                                EN CONTINUANT À UTILISER LES APPS OU LE SITE, VOUS INDIQUEZ VOTRE CONSENTEMENT AU CONDITIONS GÉNÉRALES D’UTILISATION 
                                ET AUX RÉVISIONS QUI POURRAIENT Y ÊTRE EFFECTUÉES.</p>
                            <p>Nous nous réservons à tout moment le droit de modifier ou retirer l’accès, de manière temporaire ou permanente, 
                                à des parties des Apps ou du Site et ce sans donner de préavis. Vous acceptez que nous ne sommes pas responsables 
                                à vous ou à toute tierce partie pour toute modification, suspension ou retrait des Apps et du Site ou toute partie ou fonctionnalité.</p>
                            
                            <h3>Propriété Intellectuelle</h3>
                            <p>Les marques, logos, icônes ainsi que tous les contenus tels que les images, les tracés GPS ainsi que les différents textes et descriptions, 
                                présentés sur le Site ou sur les Apps sont protégés par le Code de la propriété intellectuelle et le droit d’auteur, 
                                exceptés ceux explicitement crédités comme appartenant à des tiers ils sont alors assujettis au droit d’auteur de leur ayants-droits respectifs.</p>
                            <p>Toute reproduction, copie ou publications des contenus ou autres propriétés intellectuelles devra faire l’objet d’une demande d’autorisation 
                                préalable de leurs ayants droits, que ce ceux-ci soient Nous même ou nos partenaires. 
                                Vous vous engagez à un usage strictement privé des différents contenus mis à disposition par le Site ou les Apps. 
                                Toute utilisation ou reproduction destinées à un but commercial ou publicitaire est interdite.</p>
                            
                            <h3>Responsabilité</h3>
                            <p>Rheaparks présente à titre indicatif des informations ("Informations") à travers le contenu du Site ou des Apps sans en garantir l’exactitude, 
                                l’accessibilité ou l’exhaustivité.</p>
                            <p>Ces Informations qu’elles soient des descriptions, des itinéraires, ou des activités ne sont en aucun cas contractuelles et 
                                Nous ne serions être tenus responsables de leur utilisation ou de leur interprétation.</p>
                            <p>LES RANDONNÉES ET ACTIVITÉS PRÉSENTÉES PAR LE SITE ET LES APPS PEUVENT COMPORTER DES RISQUES Y COMPRIS ET SANS S’Y LIMITER LES RISQUES DE BLESSURES, 
                                DE DÉCÈS, AINSI QUE DE DOMMAGES MATÉRIELS. RHEAPARKS DÉCLINE TOUTE RESPONSABILITÉ CONCERNANT CES RISQUES ENCOURUS SUITE À L’USAGE DU SITE OU DES APPS.</p>
                            
                            <h3>Lois applicables</h3>
                            <p>Nous exerçons nos activités depuis la France et la législation française s’applique à ce contrat. 
                                Toute action légale concernant l’App ou le Site devra être adressée à un tribunal de la ville de Montpellier.</p>
                            <h3>Contact</h3>
                            <p>Toute question pourra être adressée via notre page contact : https://www.rheaparks.com/contact.</p>
                            
                            <h2>Mentions légales</h2>
                            <p><b>Informations générales :</b></p>
                            <ul>
                                <li><b>Identité :</b> DEBONO Sandra et CARDONNEL Clément</li>
                                <li><b>Adresse de courrier :</b> contact@rheaparks.com</li>
                                <li><b>Adresse du domicile :</b> 4 Grand Rue, Saint-Drezery.</li>
                                <li><b>Responsable de la rédaction :</b> DEBONO Sandra</li>
                            </ul>
                            <p><b>Coordonnées de l'hébergeur :</b></p>
                            <ul>
                                <li><b>Nom de l'hébergeur :</b> Netlify</li>
                                <li><b>Raison sociale :</b> Netlify, Inc.</li>
                                <li><b>Adresse :</b> 44 Montgomery Street, Suite 300, San Francisco, California 94104</li>
                                <li><b>Email :</b> support@netlify.com</li>
                                <li><b>Numéro de téléphone :</b> (415) 691-1573</li>
                            </ul>

                            <h3>Programmes d’affiliation</h3>
                            <p>Rheaparks participe au programme d’affiliation de booking.com. Ce programme d’affiliation nous permet de percevoir une rémunération grâce à la création de divers liens.</p>

                            <h2>Politique de Confidentialité</h2>
                            <p>Dernière mise à jour : 27/05/2022</p>
                            <p>Cette politique de confidentialité s’applique sur toutes les informations collectées ou envoyées au site web
                                ou aux applications de Rheaparks.</p>
                            <h3>Avant-propos</h3>
                            <p>Nous considérons que la vie privée est un droit humain fondamental et nous prenons ça très au sérieux. 
                                Rheaparks a été conçu dans l’idée de collecter le moins de données personnelles par respect pour nos utilisateurs.</p>
                            <p>En bref :</p>
                            <ul>
                                <li>Nous ne faisons pas de publicité personnalisée.</li>
                                <li>Les données anonymes sont la règle, celles qui ne le sont pas, l’exception.</li>
                                <li>Nous suivons l’usage de l’application et du site afin de déterminer les fonctions ou les randonnées les plus populaires. 
                                    Ces données sont anonymes, l’intérêt pour nous est d’avoir un aperçu global de l’utilisation de Rheaparks et non savoir 
                                    ce que font nos utilisateurs individuellement.</li>
                            </ul>
                            <p>Nous avons à cœur d’être transparent sur ces questions. Si vous avez la moindre question à ce sujet, n’hésitez pas à nous contacter.</p>
                            
                            <h3>Les informations que nous collectons et leur utilisation</h3>
                            
                            <h4>Données à caractère personnelle</h4>
                            <p>Pour le moment, Rheaparks ne collecte aucune donnée à caractère personnel.</p>
                            
                            <h4>Informations reçues suite à l’utilisation de l’app ou du site</h4>
                            <p>Rheaparks collecte automatiquement certaines données lorsque vous interagissez avec l’app ou le site.</p>

                            <ul>
                                <li><b>Informations sur les connexions et les appareils :</b> Adresse IP (permettant de déterminer depuis quel pays vous accédez au service), 
                                    la date et l’heure de la connexion, le domaine d’origine (par exemple : <a href="https://www.google.com/">google.com</a> ou un autre moteur de recherche) 
                                    le navigateur web ou la version de l’application employée, le système d’exploitation et la manière dont vous avez interagi avec nos services.</li>
                                <li><b>Cookies :</b> Nous utilisons des cookies afin de faciliter l’utilisation du site ainsi que récolter des données d’utilisation.</li>
                                <li><b>Informations sur l’utilisation :</b> Nous exerçons un suivi de l’utilisation des applications ou du site web. Ces informations incluent par exemple mais sans s’y limiter : 
                                    les parcs, randonnées et images consultées, les profils Instagram des auteurs de photos consultés, les randonnées effectivement parcourues, les recherches effectuées… 
                                    Toutes ces données sont datées et anonymes.</li>
                                <li><b>Données de géolocalisation :</b>
                                    <ul>
                                        <li>Après avoir reçu votre consentement d’utilisation des données de localisation sur l’app ou le site, nous pouvons accéder à votre position géographique et déterminer 
                                            votre latitude et longitude, le pays, la ville ou l’adresse à laquelle vous êtes. Ces données ne sortent pas de l’appareil sauf deux exceptions :
                                            <ul>
                                                <li>Dans le cadre des informations d’utilisation et de manière indirecte, un évènement Analytics peut être envoyé lorsqu’un utilisateur commence ou termine une randonnée
                                                    ou qu’il se trouve dans un parc. Dans ce cas, un identifiant anonyme est utilisé, mais la position précise ne l’est pas.</li>
                                                <li>La position peut être envoyée sur nos serveurs via une requête réseau afin de trouver des randonnées autour de vous. Dans ce cas, la position précise peut être envoyée,
                                                    mais aucun identifiant utilisateur pas même anonyme n’est employé.</li>
                                            </ul>    
                                        </li>
                                        <li>Rheaparks peut aussi déterminer une position approximative via votre adresse IP. Cette donnée est utilisée pour le bon fonctionnement du service, afin de déterminer la langue 
                                            ou le pays de l’utilisateur et rentre dans le cadre des <i>informations sur la connexion</i> et des <i>informations sur l’utilisation</i>.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Rheaparks utilise Google Analytics dans le cadre de la collecte des Informations sur l’utilisation et se conforme <a href="https://policies.google.com/technologies/partner-sites">à la politique de vie privée du service</a>.</p>
                            <p>Sur l’application iOS de Rheaparks, Google Analytics est utilisé sans faire usage de l’identifiant de tracking publicitaire et par conséquent ne requiert pas l’affichage de la pop-up de consentement au tracking 
                                ("Demander à l’app de ne pas me suivre").</p>

                            <h4>Informations reçues de la part de tiers</h4>
                            <p>Pour le moment, Rheaparks ne reçoit aucune information de la part de tiers.</p>

                            <h3>Partage et divulgation des données</h3>
                            <p>Nous partageons des données avec des tiers dans le cadre du fonctionnement de nos services. Notamment des fournisseurs et prestataires de services destinés à l’hébergement, au cloud, 
                                d’autres services informatiques, communication et analyse. Ces tierces parties traitent et stockent nos données selon nos spécifications.</p>

                            <h3>Conservation des données</h3> 
                            <p>Nous conservons les données aussi longtemps que nécessaire pour répondre aux besoins décrits dans la présente politique de confidentialité.</p>

                            <h3>Mise à jour de vos données personnelles</h3>
                            <p>Rheaparks ne conservant aucune donnée à caractère personnel, il n’y a normalement pas besoin de modifier ou supprimer ces données. Toutefois, n’hésitez pas à nous contacter via la page contact
                                du site si vous avez des questions.</p>

                            <h3>Enfants</h3>
                            <p>Rheaparks ne s’adresse pas aux enfants de moins de 13 ans. Si vous avez des raisons de penser qu’un enfant de moins de 13 ans utilise Rheaparks et que vous voulez modifier ou 
                                supprimer des données personnelles le concernant, veuillez vous référer à la section <i>Mise à jour de vos données personnelles</i>.</p>

                            <h3>Union Européenne et RGPD</h3>
                            <p>Cette section s’applique aux ressortissants de l’espace économique européen.</p>

                            <h4>Responsable du traitement des données</h4>
                            <p>Rheaparks, représenté par Clément Cardonnel est le responsable du traitement des données à caractère personnelle. Pour nous contacter, veuillez vous référer à la page contact du site 
                                ou bien consulter les mentions légales.</p>

                            <h4>Vos droits</h4>
                            <ol>
                                <li><b>Le droit d'être informé -</b> Actuellement, Rheaparks ne gère pas d’information personnelle et n’a donc pas moyen de contacter les utilisateurs du service. 
                                    Toutefois, la page web sur laquelle est présentée cette politique de confidentialité sera mise à jour régulièrement si des changements venaient à être opérés.</li>
                                <li><b>Le droit d'accès -</b> Actuellement, Rheaparks ne gère pas d’information personnelle.</li>
                                <li><b>Le droit de rectification -</b> Actuellement, Rheaparks ne gère pas d’information personnelle.</li>
                                <li><b>Le droit à l'effacement -</b> Actuellement, Rheaparks ne gère pas d’information personnelle.</li>
                                <li><b>Le droit à la limitation du traitement -</b> Il est possible de refuser de consentir à l’utilisation des cookies sur le site web et ainsi de limiter voir bloquer 
                                    le traitement des données en votre provenance selon les circonstances. Si vos données ont été partagées à un tiers et si cela est légal nous nous efforcerons 
                                    de vous indiquer à qui ces données ont été partagées.</li>
                                <li><b>Le droit à la portabilité des données -</b> Actuellement, Rheaparks ne gère pas d’information personnelle.</li>
                                <li><b>Le droit de s'opposer -</b> Actuellement, Rheaparks ne gère pas d’information personnelle.</li>
                            </ol>
                            <p>Veuillez nous contacter via la page contact du site pour savoir comment vous pouvez exercer vos droits.</p>

                            <h3>Transferts internationaux</h3>
                            <p>Rheaparks est basé en France, mais dans le cadre de ses services peut faire appel à des prestataires basés dans l’Union Européenne et aux États-Unis. 
                                Afin d’exercer notre activité et si nécessaire aux buts décrits par la politique de confidentialité, certaines données peuvent être transmises et stockées aux États-Unis. 
                                Les États-Unis peuvent avoir des lois moins strictes quant à la protection des données personnelles que celles en vigueur dans votre pays de résidence.</p>
                            
                            <h3>Liens vers des sites web externes</h3>
                            <p>Le Site et les Applications de Rheaparks peuvent contenir des liens vers des sites web externes, non exploités ou gérés par Rheaparks, 
                                ce qui peut inclure par exemple des sites de réseaux sociaux (Sites tiers). Les informations éventuellement partagées avec ces Sites tiers seront régies par les politiques de confidentialité 
                                et les conditions d'utilisation spécifiques des Sites tiers et non par la présente Politique de confidentialité. Le fait que nous fournissions ces liens ne saurait être vu comme une approbation de ces sites 
                                ou que nous les ayons examiné d’une quelconque façon. Il est à votre discrétion de vous renseigner sur les pratiques et politiques en matière de vie privée des Sites tiers.</p>

                            <h3>Sécurité</h3>
                            <p>L’utilisation de Rheaparks est à vos propres risques. Rheaparks se conforme aux standards et bonnes pratiques du secteur pour protéger les données contre la perte ou l’accès, modification et destruction non-autorisée. 
                                Il est toutefois à noter que malgré notre bonne volonté, la sécurité de la transmission d’information par internet ou par email n’est jamais totalement sûre ou exempte d’erreur. 
                                En conséquence de cela, veuillez faire preuve de prudence à chaque étape de cette transmission. Nous ne serions être tenus pour responsable du contournement des protections et sécurités du site web,
                                des applications ou de Sites tiers.</p>

                            <h3>Votre choix</h3>
                            <p>Vous êtes libre de partager avec nous vos données ou de vous en abstenir. Toutefois certaines fonctionnalités de l’app ou du site web pourraient en être affectées 
                                et il est possible que vous ne puissiez pas y accéder ou seulement partiellement.</p>

                            <h3>Modification de la Politique de Confidentialité</h3>
                            <p>Rheaparks est un service en constante évolution. De nouvelles fonctions seront ajoutées ou supprimées. Si certains changements affectent notre utilisation des données et entrent dans le cadre de la présente 
                                Politique de Confidentialité, nous en publierons une version mise à jour sur cette page. En continuant à utiliser Rheaparks, vous acceptez tacitement 
                                la Politique de Confidentialité révisée et les pratiques qui y sont explicitées.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}